import moment from "moment";
import axios from 'axios'

export function truncateFilename(filename, maxLength = 25) {
  if (filename.length <= maxLength) {
    return filename;
  } else {
    let basename = filename.substring(0, filename.lastIndexOf('.'));
    let extension = filename.substring(filename.lastIndexOf('.'));

    let truncatedName = basename.substring(0, maxLength - extension.length) + '...' + extension;
    return truncatedName;
  }
}


export const formatDate = (timestamp) => {
  const date = moment.utc(timestamp).local();
  const now = moment()
  const duration = moment.duration(now.diff(date));
  const hours = duration.asHours();
  const minutes = duration.asMinutes();

  if (minutes < 60) {
    return `${Math.floor(minutes)} minutes ago`;
  } else if (hours < 24) {
    return `${Math.floor(hours)} hours ago`;
  } else {
    return date.format('MMMM D, YYYY');
  }
}

export const urlShortener = async (url) => {
  try {
    const response = await axios.post(
      'https://api-ssl.bitly.com/v4/shorten',
      {
        long_url: url,
      },
      {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_BITLY_TOKEN}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data.link;
  } catch (error) {
    console.error('Error shortening URL:', error);
    throw new Error('Failed to shorten URL');
  }
}